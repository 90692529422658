import axios from 'axios';
import qs from 'qs';
import { showErrorAlert } from './utils';


const api = axios.create({
  baseURL: '/api/',
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrfmiddlewaretoken',
  timeout: 300000,
  headers: {
    'X-CSRFToken': CSRF,
    'Content-type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const urlToExcludeErrorAlert = [
  'send_new_order',
  'change_services_executors_tg_status',
  'notifications',
];

api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);

      const url = error.config.url;
      if (!urlToExcludeErrorAlert.some((el) => url.includes(el))) {
        showErrorAlert();
      }

      return Promise.reject(error);
    },
);

export default api;
