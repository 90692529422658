import moment from 'moment';
import api from './api';


const MAX_NUM_OF_NOTIFICATIONS = 5;


const setNotify = (notify, newNotify) => {
  newNotify.find('._link a').attr('href', notify.urlToPage);
  newNotify.find('._link p').text(notify.descriptionDisplay);

  const _date = moment(notify.date, 'DD.MM.YYYY hh:mm').fromNow();
  newNotify.find('._date small').text(_date[0].toUpperCase() + _date.slice(1));

  newNotify.find('._delete').attr('data-notify-id', notify.id);

  return newNotify;
};

const setNotificationsTitle = (notificationsLen) => {
  let notificationsTitle = '';

  if (notificationsLen > MAX_NUM_OF_NOTIFICATIONS) {
    notificationsTitle += `${MAX_NUM_OF_NOTIFICATIONS}/${notificationsLen}`;
  } else notificationsTitle += `${notificationsLen}`;

  $('.notifications-count').text(`${notificationsTitle}`);
};

const updateNotifications = (currentCount=0) => {
  let notificationsLen = currentCount;
  api({
    method: 'get',
    url: 'notifications/',
    params: {
      pageSize: 100,
      page: 1,
    },
  }).then((response) => {
    if (response && response.data) {
      const listNotifications = $('.list-notifications');
      listNotifications.html('');

      notificationsLen = response.data.count;
      setNotificationsTitle(notificationsLen);

      if (currentCount < notificationsLen) {
        $('.unread-notifications').show().removeClass('d-none');
        $('.notifications .icon-notifications').removeClass('text-dark').addClass('text-danger');
      }

      if (notificationsLen === 0) {
        $('.not-notifications').removeClass('d-none');
      } else {
        let results = [];
        if (notificationsLen > MAX_NUM_OF_NOTIFICATIONS) {
          results = response.data.results.slice(0, MAX_NUM_OF_NOTIFICATIONS);
        } else results = response.data.results;

        $('.not-notifications').addClass('d-none');
        (results || []).forEach((notify) => {
          const newNotify = $('.empty-notification > div').clone();
          listNotifications.append(setNotify(notify, newNotify));
        });
      }
    }
  }).finally(() => setTimeout(() => updateNotifications(notificationsLen), 20000));
};


export const initNotifications = () => {
  if ($('.notifications').length !== 0) updateNotifications();

  $('.notifications').on('click', '._delete', function(event) {
    event.preventDefault();
    event.stopPropagation();

    const norificationId = $(this).attr('data-notify-id');

    const notification = $(this).closest('.notification');
    notification.addClass('d-none');

    api({
      method: 'put',
      url: `notifications/${norificationId}/`,
      data: {
        isReaded: true,
      },
    }).then((response) => {
      let notificationsCount = $('.notifications-count').text();
      const notificationsCountSplited = notificationsCount.split('/');
      notificationsCount = notificationsCountSplited[0];
      updateNotifications(notificationsCount-1);
    }).catch((error) => notification.removeClass('d-none'));
  });
};
