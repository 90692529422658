import moment from 'moment';
import Cookie from 'js-cookie';

import { cookieAcceptedName } from '@/defaults';


const formatDate = (date, outFormat='DD.MM.YYYY', inFormat='YYYY-MM-DD') => {
  return moment(date, inFormat).format(outFormat);
};


const getCheckedsIds = () => {
  return Array.from(
      document.querySelectorAll('table .app-checkbox:checked'),
  ).map((el) => el?.value).filter((el) => !!el);
};


const resetCheckeds = () => $('table input:checkbox').prop('checked', false);


const downloadFile = (data, filename) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(data);

  link.href = url;
  link.download = filename;

  document.body.append(link);

  link.click();
  link.remove();

  URL.revokeObjectURL(url);
};


const toggleExcelModalLoadingMask = (isShow) => {
  const loadingMask = $('#excelModal form .loading-mask');
  if (isShow) loadingMask.addClass('d-flex').removeClass('d-none');
  else loadingMask.removeClass('d-flex').addClass('d-none');
};


const toggleExcelModalButtonSubmit = (toDisable) => {
  $('#excelModal form button[type="submit"]').attr('disabled', toDisable);
};


const dismissAlerts = () => $('.alert').addClass('d-none');


const reloadTable = () => {
  $('#reload-table').trigger('click');
  $('table').trigger('table:updated');
  resetCheckeds();
};

/**
 * Function initSelect2 - used to initialize select2 elements
 * @param {string[]} selectors to init
 * @param select2Params
 * @returns {Null} init select2 elements withour return result
 */

const initSelect2 = (selectors, select2Params={}) => {
  if (typeof(selectors) === 'string') selectors = [selectors];

  return $(selectors.join(', ')).select2(select2Params);
};


const getUrlToForm = (objectId) => !!objectId ? `${objectId}/put_v2/` : 'create_v2/';


const isCookieAccepted = () => !!Cookie.get(cookieAcceptedName);


const getIdFromUrl = () => {
  const pathnames = window.location.pathname.split('/');
  let _id = 0;

  if (pathnames.length > 0) _id = pathnames[pathnames.length-1];
  if (!_id && pathnames.length > 1) _id = pathnames[pathnames.length-2];

  return _id;
};


const showAlert = (alertId) => $('#' + alertId).removeClass('d-none');


const timeoutShowAlert = (alertId) => {
  showAlert(alertId);
  setTimeout(() => $('#' + alertId).find('button.close').trigger('click'), 5000);
};


const showErrorAlert = () => $('#errorAlert').removeClass('d-none');


const hideAlert = (alertId) => $('#' + alertId).addClass('d-none');


const initAppsChecker = () => {
  $('#appCheckAll').on('click', function() {
    $('#appTable .app-checkbox').prop('checked', $(this).is(':checked'));
  });
};

const getSumByKey = (iterator, key) => iterator.reduce((sum, value) => sum + (value[key] || 0), 0);

const getPaymentVisibleFields = (isSelfEmployed, isIndividual) => {
  if (isSelfEmployed) {
    return {
      showFields: ['full-name', 'inn'],
      hideFields: ['company-name', 'bank-name', 'number']
    };
  }
  if (isIndividual) {
    return {
      showFields: ['company-name', 'inn'],
      hideFields: ['full-name', 'bank-name', 'number']
    };
  }
  return {
    showFields: ['bank-name', 'number'],
    hideFields: ['full-name', 'company-name', 'inn']
  };
};


export {
  formatDate,
  getCheckedsIds,
  resetCheckeds,
  downloadFile,
  toggleExcelModalLoadingMask,
  toggleExcelModalButtonSubmit,
  dismissAlerts,
  reloadTable,
  initSelect2,
  getUrlToForm,
  isCookieAccepted,
  getIdFromUrl,
  showAlert,
  timeoutShowAlert,
  showErrorAlert,
  hideAlert,
  initAppsChecker,
  getPaymentVisibleFields,
  getSumByKey
};
