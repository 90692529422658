/* LIBS */
import $ from 'jquery';
global.jQuery = $;
global.$ = $;

global.DADATA_TOKEN = process.env.DADATA_API;

// import './sentry';

import '@popperjs/core';
import 'bootstrap';

import 'select2/dist/js/select2.full.min.js';

import 'suggestions-jquery';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css';

import 'suggestions-jquery/dist/css/suggestions.min.css';

import './../django_static/admin/css/vendor/select2/select2.min.css';
import './../django_static/admin/css/vendor/select2/select2-bootstrap-5-theme.min.css';

import './../scss/volt.scss';
import './../scss/main.scss';
