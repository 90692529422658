import 'select2/dist/js/i18n/ru.js';
$.fn.select2.defaults.set('language', 'ru');
$.fn.select2.defaults.set('theme', 'bootstrap-5');
$.fn.select2.defaults.set('placeholder', '---');

import Cookie from 'js-cookie';

import { cookieAcceptedName } from './defaults';
import { isCookieAccepted } from './plugins/utils';
import { initNotifications } from './plugins/norifications';


document.addEventListener('DOMContentLoaded', (event) => {
  initNotifications();

  $('#sidebarMenu .nav-item').each(function() {
    const aInsideLiElement = $(this).find('.nav-link');
    if (window.location.href.includes(aInsideLiElement.attr('href'))) {
      if ($(this).hasClass('no-active')) return;
      $(this).addClass('active');

      let newTitle = $(this).find('a')[0].text.trim().replace(/\s{2,}/g, ' ');
      if (newTitle.includes('Уведомления')) {
        newTitle = `${newTitle.split(' ')[0]} - Новых ${newTitle.split(' ')[1]}`;
      };
      document.title = `${document.title} - ${newTitle}`;
    } else $(this).removeClass('active');
  });


  const cookieAcceptElement = $('#cookieAccept');
  if (!isCookieAccepted()) cookieAcceptElement.removeClass('d-none');
  cookieAcceptElement.find('#cookieAcceptSubmit').on('click', () => {
    Cookie.set(cookieAcceptedName, 1, { expires: 100 });
    cookieAcceptElement.addClass('d-none');
  });


  const isCollapsedSidebarMenuCookieName = 'isCollapsedSidebarMenu';
  const toggleSidebarMenu = () => {
    const _this = $('.toggle-collapse');
    const isCollapsed = _this.data('is-collapsed');

    $('.sidebar, .content').toggleClass('collapsed');
    if (isCollapsed) _this.html('<span class="fas fa-toggle-on"></span>');
    else _this.html('<span class="fas fa-toggle-off"></span>');
    _this.data('is-collapsed', !isCollapsed);

    Cookie.set(isCollapsedSidebarMenuCookieName, isCollapsed ? 0 : 1, { expires: 100 });
  };
  $('.toggle-collapse').on('click', () => toggleSidebarMenu());

  const isCollapsedSidebarMenu = Cookie.get(isCollapsedSidebarMenuCookieName);
  if (isCollapsedSidebarMenu === '0') toggleSidebarMenu();

  $('#backButton').on('click', function() {
    window.location = document.referrer;
    return false;
  });

  $('.table').on('show.bs.dropdown', () => {
    $('.table').closest('.card').addClass('visible-dropdown-clip');
  });
  $('.table').on('hide.bs.dropdown', () => {
    $('.table').closest('.card').removeClass('visible-dropdown-clip');
  });

  $('.alert button.close').on('click', function() {
    $(this).closest('.alert').addClass('d-none').removeClass('d-block');
  });
});
